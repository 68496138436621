import styled from "styled-components"
export const Container = styled.div`
    position: fixed;
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05);
    color: #000;
    h2{
        font-size: 3rem;
    }

    span{
        font-size: 2rem;
    }
`


