import HeaderGlobal from "../../components/Header/Header";
import { Container } from "./styled";

function Fotos() {
    return (
        <>
        <HeaderGlobal/>
            <Container>
                <h2>
                    404
                </h2>

                <span>Página não encontrada.</span>

                <small>A rota que você digitou não consta no nosso sistema.</small>
            </Container>

        </>
    )
}

export default Fotos;