import styled from 'styled-components'
import * as c from '../../config/default'

export const Container = styled.div`
  ${c.TitleFont}
  ${c.DescriptionFont}
  background-color: #001F3F;
  width: 100%;
  height: fit-content;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  flex-direction: row;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: .5px;
    background-color: #ffffff50;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterColumn = styled.div`
  color: #fff;
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
    align-items: center;
    text-align: center;
  }
`;



export const FooterList = styled.div`
  font-family: "Quicksand";
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterItem = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  text-align: center;
  gap: 1rem;
  :hover {
    color: #339933;
  }
`;

export const TeamSection = styled.div`
  ${c.TitleFont}
  ${c.DescriptionFont}
  background-color: #001F3F;
  width: 100%;
  color: #fff;
  font-family: "Quicksand";
  margin-top: 2rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: .5px;
    background-color: #ffffff50;
  }
`;

export const MemberList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0;
  margin: 0;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;



export const MemberItem = styled.li`
  margin-top: 1rem;
  color: #fff;
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
    align-items: center;
    text-align: center;
  }
`;

export const MemberResp = styled.div`
  font-size: 1.2rem;
  :hover {
    color: #339933;
  }
`

export const MemberName = styled.p`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  :hover {
    color: #339933;
  }
`;

export const MemberRole = styled.p`
  font-size: .8rem;
  color: #999;
`;
