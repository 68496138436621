import React from 'react';
import { Wrapper, Spinner } from "./styled"

function Loading() {
    return (
        <>
            <Wrapper>
                <Spinner />
            </Wrapper>
        </>
    
    )
};

export default Loading;