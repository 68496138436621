import styled from 'styled-components';

export const SliderContainer = styled.div`
position: relative;
width: 100%;
height: 100%;
overflow: hidden;
background-color: #000;
`;

export const Slide = styled.img`
object-fit: cover;
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
transition: opacity 2s ease-in-out;
`;
