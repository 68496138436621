import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 5;
  @font-face {
  font-family: 'Noto Serif';
  src: url('/fonts/NotoSerif-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
`;

export const Spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 0.3rem solid #f3f3f3;
  border-top: 0.3rem solid #001F3F;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  animation: ${Spin} 0.6s linear infinite;
`;

