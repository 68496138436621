import React, { useState, useEffect } from "react";
import NavigationBy from '../../components/NavigationBy/NavigationBy'
import Pagination from "../../components/Paginator/Paginator"
import { Container, Objects, Content, Item, Count, Producer, HeaderContent, HeaderContainer } from "./styled";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderGlobal from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import { api } from "../../services/api";

function Produtores() {
    const navigate = useNavigate()
    const { page } = useParams()
    const location = useLocation()
    const arrayRoute = location.pathname.split('/')
    const currentRoute = arrayRoute[1]
    const [loading, setIsLoaging] = useState(false)
    const [dados, setDados] = useState([])
    const [quantidade, setQuantidade] = useState(0)
    const [currentPage, setCurrentPage] = useState(Number(page));
    const pageSize = 9;


    const handlePageChange = (page) => {

        setCurrentPage(page);
        navigate(`/${currentRoute}/${page}`);
        window.history.pushState({ page }, '', `/${currentRoute}/${page}`);
    };


    useEffect(() => {
        async function getData() {
            setIsLoaging(true)
            const resp = await api.get('produtores/show_all', {
                params: {
                    page: currentPage
                }
            })
            setQuantidade(resp.data.count)
            setDados(resp.data.results)
            setIsLoaging(false)
        }
        getData()

        const state = window.history.state;
        if (state && state.page) {
            setCurrentPage(state.page);
        }
    }, [currentPage])
    
    return (
        <>
            <HeaderGlobal />
            <Container>
                
                <HeaderContainer>
                        <HeaderContent>
                            {
                                quantidade > 0 ? (
                                    <span>{quantidade} produtor{quantidade > 1 ? 'es' : ''} encontrado{quantidade > 1 ? 's.' : '.'}</span>
                                ) :
                                    (
                                        <span>{!loading ? `Sem produtores adicionados.` : ''}</span>
                                    )
                            }
                        </HeaderContent>
                    </HeaderContainer>
                <Content>
                    
                    <NavigationBy />

                    <Objects className="objetcs">
                    {loading && <Loading />}
                        {
                            dados.map(obj => (
                                <Item key={dados.indexOf(obj)}>
                                    <Link to={`/relacionados/${obj.slug}`}>
                                        <Producer>{obj.nome_produtor}</Producer>
                                        <Count>{obj.objects_count > 0 ? obj.objects_count > 1 ? obj.objects_count + ' arquívos.' : obj.objects_count + ' arquívo' : 'Sem arquívos.'}</Count>
                                    </Link>
                                </Item>
                            ))
                        }
                    </Objects>

                </Content>
                {quantidade > 0 && <Pagination
                    totalSize={quantidade}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />}

            </Container>

        </>
    )
}

export default Produtores;