import styled from "styled-components";
import * as c from '../../config/default'
export const SidebarContainer = styled.div`
    @media (min-width: 818px){
            display: none;
        }
    position: fixed;
    top: 0;
    transition: all 300ms;
    right: ${props => props.isActive ? '0' : '-100%'};
    background-color: #fff;
    -webkit-box-shadow: 5px 2px 18px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 2px 18px -3px rgba(0,0,0,0.75);
    box-shadow: 5px 2px 18px -3px rgba(0,0,0,0.75);
    height: 100vh;
    z-index: 100;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;



export const Container = styled.div`
    height: 100vh;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    width: 20rem;
    gap: 2rem;
    flex-direction: column;
    position: relative;

    a{
        text-decoration: none;
        width: 80%;
    }
`;





export const FormContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    
`;

export const Form = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20rem;
    height: 2.5rem;
    padding-left: .5rem;
    position: relative;
    outline: none;
    background-color: white;
    border: 3px solid #001F3F50;
    
    
`;

export const Input = styled.input`
    width:100%;
    height: 100%;
    border: none;
    background:none;
    outline: none;
`;

export const Button = styled.button`
    cursor: pointer;
    background: none;
    border-left: 1px solid #00000050;
    position: absolute;
    right: 2px;
    svg{
        font-size: 1rem;
    }
`;

export const Item = styled.div`
    ${c.TitleFont}
    width: 100%;
    color: ${c.BackgroundHeader};
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Mulish', sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    transition: all 100ms;
    width: 100%;
    &:hover {
        color: #001F3F98;
    }

    @media (min-width: 480px) and (orientation: landscape) {
        font-size: 1rem;
    }
`;