import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Container, SidebarContainer, FormContainer, Form, Input, Button, Item} from "./styled";

import { Link, useNavigate } from "react-router-dom";

function Sidebar({ isSidebarActive }) {
    const navigate = useNavigate()
    const [query, setQuery] = useState('')

    

    function handleSubmit(e) {
        e.preventDefault()
        if (query.length === 0) return

        navigate(`/search/${query}`)
    }

    return (
        <SidebarContainer isActive={isSidebarActive}>
            <Container>
            
                <FormContainer>
                    <Form onSubmit={handleSubmit}>
                        <Input type={'text'} value={query} onChange={e => setQuery(e.target.value)} placeholder={'O que está procurando?'} />
                        <Button><FaSearch /></Button>
                    </Form>
                </FormContainer>
                <Link to={'/'}>
                    <Item Text="">Home</Item>
                </Link>
                <Link to={'/imagens/1'}>
                    <Item Text="">Imagens</Item>
                </Link>
                <Link to={'/videos/1'}>
                    <Item Text="">Vídeos</Item>
                </Link>
                <Link to={'/documentos/1'}>
                    <Item Text="">Documentos</Item>
                </Link>
                <Link to={'/albuns/1'}>
                    <Item Text="">Álbuns de Fotografias</Item>
                </Link>

            </Container>

        </SidebarContainer>

    )
}

export default Sidebar;