import { FaInstagram } from "react-icons/fa"
import { AiOutlinePhone } from "react-icons/ai"
import { HiOutlineMail } from "react-icons/hi"
import { Container, FooterColumn, FooterList, FooterItem, TeamSection, MemberList, MemberItem, MemberRole, MemberName, MemberResp } from "./styled"

export default function Footer() {
    return (
            <Container>
                <FooterColumn>
                    <FooterList>
                        <FooterItem>
                            Instituto Federal do Rio Grande do Norte
                        </FooterItem>
                        <FooterItem>
                            Campus Natal - Central
                        </FooterItem>
                        <FooterItem>
                            Diretoria de Administração
                        </FooterItem>
                        <FooterItem>
                            Arquivo Geral
                        </FooterItem>

                    </FooterList>
                </FooterColumn>

                <FooterColumn>
                    <FooterList>
                        <FooterItem>
                            Avenida Senador Salgado Filho, 1559
                        </FooterItem>
                        <FooterItem>
                            Tirol, Natal-RN
                        </FooterItem>
                        <FooterItem>
                            CEP 59015-000

                        </FooterItem>
                        <FooterItem>
                            8h às 12h - 13h às 17h
                        </FooterItem>
                    </FooterList>
                </FooterColumn>

                <FooterColumn>
                    <FooterList>
                        <FooterItem>
                            <HiOutlineMail />
                            arquivo.cnat@ifrn.edu.br
                        </FooterItem>
                        <FooterItem>
                            <AiOutlinePhone />
                            84 4005-9825
                        </FooterItem>
                        <FooterItem>
                            <FaInstagram />
                            @arquivoifrncnat
                        </FooterItem>
                    </FooterList>
                </FooterColumn>
                
                <TeamSection>
                    <MemberList>
                        <MemberItem>
                            <MemberResp>Desenvolvimento e Suporte</MemberResp>
                            <MemberName>Renan do Nascimento Lisboa</MemberName>
                            <MemberRole>Aluno do Curso de Análise e Desenvolvimento de Sistemas</MemberRole>
                        </MemberItem>
                        <MemberItem>
                            <MemberResp>Descrição e inserção</MemberResp>
                            <MemberName>Arilene Lucena de Medeiros</MemberName>
                            <MemberRole>Historiadora e Coordenadora do Arquivo</MemberRole>
                        </MemberItem>

                        <MemberItem>
                            <MemberResp>Orientação e suporte</MemberResp>   
                            <MemberName>Danielle Gomes de Freitas Medeiros</MemberName>
                            <MemberRole>Professora da Diretoria de Tecnologia da Informação (DIATINF)</MemberRole>
                        </MemberItem>
                    </MemberList>
                </TeamSection>
            </Container>
    )
}