import HeaderGlobal from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer"
import Slider from "../../components/SlideHome/Slide";
import { IoMdAlbums } from "react-icons/io"
import { FaUniversity } from "react-icons/fa"
import { BiCategory } from "react-icons/bi"
import { BsImage } from "react-icons/bs"
import { HiDocument } from "react-icons/hi"
import { BsFillCameraVideoFill } from "react-icons/bs"
import { AcervoInfo, Container, Item, ItemDescription, ItemIcon, Objects, Paragraph, Section, Title, Text, Sobre, Uso, TextUso, TitleUso } from "./styled";
import { Link } from "react-router-dom";


function Home() {
    return (
        <>
            <HeaderGlobal />
            <Container>
                <AcervoInfo>
                    <Paragraph>
                        Essa plataforma visa dar acesso aos documentos que registram a história do IFRN
                        e que estão sob a guarda permanente do Arquivo Geral do Campus Natal-Central. Será
                        constituída por textos, fotografias, mapas e vídeos. Pretende abarcar o período de 1909 a 2009,
                        desde a Escola de Aprendizes Artífices de Natal ao Centro Federal de Educação Profissional e
                        Tecnológica (CEFET-RN).
                    </Paragraph>
                </AcervoInfo>
                <Slider />
            </Container>
            <Section>

                <Objects>
                    <Item>
                        <ItemIcon><BsFillCameraVideoFill /></ItemIcon>
                        <ItemDescription><Link to={'/videos/1'}>Vídeos</Link></ItemDescription>
                    </Item>
                    <Item>
                        <ItemIcon><BsImage /></ItemIcon>
                        <ItemDescription><Link to={'/imagens/1'}>Imagens</Link></ItemDescription>
                    </Item>
                    <Item>
                        <ItemIcon><IoMdAlbums /></ItemIcon>
                        <ItemDescription ><Link to={'/albuns/1'}>Álbuns de <br /> fotografias</Link></ItemDescription>
                    </Item>
                    <Item>
                        <ItemIcon><HiDocument /></ItemIcon>
                        <ItemDescription><Link to={'/documentos/1'}>Documentos</Link></ItemDescription>
                    </Item>
                    <Item>
                        <ItemIcon><FaUniversity /></ItemIcon>
                        <ItemDescription><Link to={'/produtores/1'}>Produtores</Link></ItemDescription>
                    </Item>
                    <Item>
                        <ItemIcon><BiCategory /></ItemIcon>
                        <ItemDescription><Link to={'/temas/1'}>Temas</Link></ItemDescription>
                    </Item>



                </Objects>
            </Section>

            <Sobre>
                <Title>SOBRE O PROJETO</Title>
                <Text>O desenvolvimento dessa ferramenta de acesso ao acervo arquivístico institucional se deu por iniciativa da Coordenação do Arquivo Geral do IFRN/Campus Natal-Central, a partir de gestões feitas em 2022 junto à Pró-Reitoria de Pesquisa e Inovação (PROPI), que destinou cinco vagas de bolsistas de apoio técnico ao Setor, com orçamento oriundo de Emendas Parlamentares.
                </Text>
                <Text>A elaboração de uma plataforma para servir ao propósito de difusão do acervo documental histórico do IFRN constituía uma aspiração desde as comemorações do centenário institucional, em 2009, cujas atividades foram coordenadas pela equipe da então Coordenadoria de Comunicação Social do Campus Natal-Central, a qual detinha a custódia do acervo fotográfico.</Text>
                <Text>Lançado em 2003, o projeto "CEFET-RN a caminho do centenário" não só permitiu a produção de exposições fotográficas e de memórias orais, gravadas em vídeo, como possibilitou "descobrir" e recuperar um acervo documental de imenso valor histórico presente no então chamado "Arquivo Morto", bem como uma série de produções audiovisuais da antiga Coordenação de Multimídia.
                </Text>
                <Text>Tais festividades foram importantes para despertar uma consciência entre os gestores e a comunidade escolar acerca da importância de se organizar e preservar a memória institucional. Dessa forma, em 2011, o IFRN criou duas vagas de arquivistas no seu quadro funcional, iniciativa que permitiu converter uma imensa massa documental acumulada no atual Arquivo Geral, que se encontra em estágio parcialmente organizado.</Text>
                <Text>O acervo fotográfico que será inserido neste portal, paulatinamente, foi digitalizado como parte das atividades daquelas comemorações, entre 2009 e 2010, com o apoio de alunos-bolsistas pagos com recursos da então Diretoria de Pesquisa.  </Text>
                <Text>Já a documentação textual, selecionada de acordo com seu potencial como fonte para pesquisas acadêmicas, vem sendo digitalizada desde 2019 pela própria Coordenação do Arquivo, em paralelo às atividades de rotina do Setor, como atendimento aos usuários e organização do acervo. </Text>
                <Text>Para inaugurar a plataforma, priorizamos, num primeiro momento, a disponibilização de parte do acervo relacionado ao Curso Técnico de Mineração, que, em 2023, completa 60 anos de criação e de oferta inunterrupta.</Text>
            </Sobre>

            <Uso>
                <TitleUso>Uso e reprodução do acervo digital</TitleUso>
                <TextUso>É permitida a utilização e/ou reprodução de documentos textuais, fotográficos, audiovisuais e cartográficos contidos nesta plataforma, desde que sejam atribuídos os créditos como fonte de pesquisa à Plataforma do Acervo Arquivístico do Arquivo Geral do IFRN/Campus Natal-Central.</TextUso>
            </Uso>

            <Footer />
        </>
    )
}

export default Home;