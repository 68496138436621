import styled from "styled-components";
import * as c from "../../config/default"
export const Container = styled.div`
@font-face {
  font-family: 'Noto Serif';
  src: url('/fonts/NotoSerif-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
width: 100%;
height: 100vh;
position: relative;
`;

export const AcervoInfo = styled.div`
width: 100%;
height: 100%;
margin: 0 auto;
z-index: 10;
position: absolute;
top: 0;
display: flex;
align-items: center;
text-align: center;
justify-content: center;
flex-direction: column;
color: #fff;
background-color: #00000090;
font-family: 'Noto Serif', serif;
text-shadow: 1px 3px 10px rgba(0, 0, 0, 0.25);
`;


export const Paragraph = styled.p`
    width:70%;
    text-align: center;
    font-size: 1.2rem;
    @media (max-width:680px){
        width:90%;
        font-size:.9rem;
    }
`;

export const Section = styled.section`
@font-face {
  font-family: 'Noto Serif';
  src: url('/fonts/NotoSerif-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
height: 100vh;
width: 100%;
padding: 1rem 0;
display: flex;
align-items: center;
justify-content: center;
`;

export const Objects = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 50%;
  height: 70%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 1rem;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  font-family: 'Noto Serif', serif;
`;

export const Item = styled.li`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  @media (max-width: 768px) {
    justify-content: flex-start;
    font-size: 1rem;
  }
  color: #001F3F;
  text-transform: uppercase;
`;

export const ItemIcon = styled.div`
font-size:2rem;
display: flex;
justify-content: center;
align-items: center;
`;

export const ItemDescription = styled.div`
font-size:1.5rem;
text-align: center;
a{
  text-decoration: none;
  color: #001F3F;
}
`;


/*** */
export const Title = styled.h1`
  font-family: "Quicksand", sans-serif;
  text-align: center;
  margin-bottom: 1rem;
`;

export const Text = styled.p`
  font-family: "Mulish", sans-serif;
  text-align: center;
  line-height: 1.5rem;
  margin-bottom: 2rem;
`;

export const Sobre = styled.div`
  ${c.TitleFont}
  ${c.DescriptionFont}
  max-width: 80%;
  margin: auto;
  position: relative;
  padding: 1rem 0;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: .5px;
    background-color: #00000050;
  }
`;

/*** */

export const Uso = styled.div`
  ${c.TitleFont}
  ${c.DescriptionFont}
  ${c.Open}
  background-color: #001F3F;
  color: #fff;
  max-width: 100%;
  padding:1rem 6rem;
`;
export const TitleUso = styled.div`
  font-family: "Quicksand", sans-serif;
  text-align: center;
  margin: 1rem 0;
  font-size: 2rem;
`;

export const TextUso = styled.p`
  font-family: "Mulish", sans-serif;
  text-align: center;
  line-height: 1.5rem;
  margin-bottom: 1rem;
`;
