import React, { useState, useEffect } from "react";
import NavigationBy from '../../components/NavigationBy/NavigationBy'
import Pagination from "../../components/Paginator/Paginator"
import { Container, Objects, Content, Item, Category, Count, HeaderContent, HeaderContainer, Select, Option } from "./styled";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderGlobal from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";

import { api } from "../../services/api";

function Categorias() {
    const navigate = useNavigate()
    const { page } = useParams()
    const location = useLocation()
    const arrayRoute = location.pathname.split('/')
    const currentRoute = arrayRoute[1]
    const [loading, setIsLoaging] = useState(false)
    const [dados, setDados] = useState([])
    const [quantidade, setQuantidade] = useState(0)
    const [currentPage, setCurrentPage] = useState(Number(page));
    const pageSize = 9;
    const [letra, setLetra] = useState('')

    const handleSelect = (event) => {
        setLetra(event.target.value);
    }

    const handlePageChange = (page) => {

        setCurrentPage(page);
        navigate(`/${currentRoute}/${page}`);
        window.history.pushState({ page }, '', `/${currentRoute}/${page}`);
    };


    useEffect(() => {
        async function getData() {

            setIsLoaging(true)
            const resp = await api.get('categorias/show_all', {
                params: {
                    page: currentPage,
                    letra: letra
                }
            })
            
            setQuantidade(resp.data.count)
            setDados(resp.data.results)
            setIsLoaging(false)

        }
        getData()
    }, [currentPage, letra])
    

    return (
        <>
            <HeaderGlobal />
            <Container>
                
                <HeaderContainer>
                    <HeaderContent>
                        {
                            quantidade > 0 ? (
                                <span>{quantidade} tema{quantidade > 1 ? 's' : ''} encontrado{quantidade > 1 ? 's.' : '.'}</span>
                            ) :
                                (
                                    <span>{!loading ? (letra !== '' ? `Sem temas com a letra ${letra}.` : `Sem temas adicionados.`) : ''}</span>
                                )
                        }
                    </HeaderContent>
                </HeaderContainer>
                <Content>
                    <Select value={letra} onChange={handleSelect}>
                        <Option value="">Filtre pela letra</Option>
                        <Option value="A">A</Option>
                        <Option value="B">B</Option>
                        <Option value="C">C</Option>
                        <Option value="D">D</Option>
                        <Option value="E">E</Option>
                        <Option value="F">F</Option>
                        <Option value="G">G</Option>
                        <Option value="H">H</Option>
                        <Option value="I">I</Option>
                        <Option value="J">J</Option>
                        <Option value="K">K</Option>
                        <Option value="L">L</Option>
                        <Option value="M">M</Option>
                        <Option value="N">N</Option>
                        <Option value="O">O</Option>
                        <Option value="P">P</Option>
                        <Option value="Q">Q</Option>
                        <Option value="R">R</Option>
                        <Option value="S">S</Option>
                        <Option value="T">T</Option>
                        <Option value="U">U</Option>
                        <Option value="V">V</Option>
                        <Option value="W">W</Option>
                        <Option value="X">X</Option>
                        <Option value="Y">Y</Option>
                        <Option value="Z">Z</Option>
                    </Select>
                    <NavigationBy />

                    <Objects className="objetcs">
                    {loading && <Loading />}
                        {
                            dados.map(obj => (
                                <Item key={dados.indexOf(obj)}>
                                    <Link to={`/relacionados/${obj.slug}`}>
                                        <Category>{obj.nome_categoria}</Category>
                                        <Count>
                                            {obj.objects_count > 0 ? obj.objects_count > 1 ? obj.objects_count + ' arquívos.' : obj.objects_count + ' arquívo' : 'Sem arquívos.'}
                                        </Count>
                                    </Link>
                                </Item>
                            ))
                        }
                    </Objects>

                </Content>
                {quantidade > 0 && <Pagination
                    totalSize={quantidade}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />}

            </Container>

        </>
    )
}

export default Categorias;