import React, { useState, useEffect } from 'react';
import {SliderContainer, Slide} from "./styled.js"
import i1 from "../../assets/img/img1.jpg"
import i2 from "../../assets/img/img2.jpg"
import i3 from "../../assets/img/img3.jpg"
import i4 from "../../assets/img/img4.jpg"
import i6 from "../../assets/img/img6.jpg"
import i7 from "../../assets/img/img7.jpg"
import i8 from "../../assets/img/img8.jpg"
import i9 from "../../assets/img/img9.jpg"
import i10 from "../../assets/img/img10.jpg"




const slides = [
  {
    id: 1,
    url: i1
  },
  {
    id: 2,
    url: i2
  },
  {
    id: 3,
    url: i3
  },
  {
    id: 4,
    url: i4
  },
  {
    id: 6,
    url: i6
  },
  {
    id: 7,
    url: i7
  },
  {
    id: 8,
    url: i8
  },
  {
    id: 9,
    url: i9
  },
  {
    id: 10,
    url: i10
  }
];

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(prevSlide =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 6000);

    return () => interval;
  }, []);

  const renderSlides = (index) => {
    return slides.map((slide, i) => (
      <Slide
        key={slide.id}
        src={slide.url}
        style={{ opacity: i === index ? 1 : 0, zIndex: i === index ? 1 : 0 }}
      />
    ));
  };

  return (
    <SliderContainer>
      {renderSlides(currentSlide)}
    </SliderContainer>
  );
};

export default Slider;
